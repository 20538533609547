<script setup>
import { ref, computed, defineEmits, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { validateForms, reqString, schemaMaker } from '@valid/JoiValidation'
import { getCustomerGift } from '@service/CustomerService'

const emit = defineEmits(['submit-gift-form'])
const store = useStore()

const btnLoading = ref(false)
const giftCode = computed({
  get() {
    return store.state.footer.giftCode
  },
  set(value) {
    store.commit('SET_GIFT_CODE', value.toUpperCase())
  }
})

const searchCustomerGift = async () => {
  // get items
  const formItems = { code: giftCode.value }
  const validCode = schemaMaker({
    code: reqString('کد بن خرید')
  })
  const errors = validateForms(formItems, validCode)

  if (errors && errors.length > 0) {
    for (let key of errors) {
      const message = { type: 'error', message: key }
      store.dispatch('addToast', message)
    }
    return
  }

  btnLoading.value = true
  try {
    await getCustomerGift(formItems)
    emit('submit-gift-form', 'success')
  } catch (ex) {
    if (ex.response) {
      switch (ex.response.status) {
        case 404:
          store.dispatch('addToast', {
            type: 'error',
            message: 'بن در سیستم موجود نمی‌باشد.'
          })
          break

        default:
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
          break
      }
    }
    emit('submit-gift-form', 'error')
  } finally {
    btnLoading.value = false
  }
}

const input = ref(null)
onMounted(() => {
  nextTick(() => {
    input.value.$el.querySelector('input').focus()
    setTimeout(() => window.scrollTo({ top: 400 }), 100)
  })
})
</script>

<template>
  <form
    novalidate
    class="modal-form-wrapper"
    @submit.prevent="searchCustomerGift"
  >
    <h4 class="footer-modal__text">
      دریافت بن خرید
    </h4>
    <base-input
      label="کد بن خرید"
      start-left
      phone-number
      v-model="giftCode"
      ref="input"
    />
    <div class="credit-buttons">
      <button type="submit" class="button button--black">
        <svg
          class="button__icon"
          viewBox="0 0 700 512"
          style="width:34px;height: 34px;"
          v-show="!btnLoading"
        >
          <path
            fill="#c69d4e"
            d="M389.45 192.38l0 -0.03 0.28 -0.03c7.01,-0.63 12.4,-6.53 12.4,-13.58 0,-7.05 -5.39,-12.95 -12.4,-13.58l-0.28 -0.03 0 -0.03 -26.76 0 0 -28.74 -0.03 0 -0.02 -0.27c-0.64,-7.02 -6.54,-12.4 -13.59,-12.4 -7.04,0 -12.95,5.38 -13.58,12.4l-0.02 0.27 -0.03 0 0 28.74 -2.86 0c-28.06,0 -51.02,22.96 -51.02,51.02 0,28.06 22.96,51.03 51.02,51.03l32.99 0c13.06,0 23.74,10.68 23.74,23.74 0,13.06 -10.68,23.75 -23.74,23.75l-56.89 0 0 0.03 -0.28 0.02c-7.01,0.64 -12.39,6.54 -12.39,13.59 0,7.04 5.38,12.95 12.39,13.58l0.28 0.02 0 0.03 26.76 0 0 28.75 0.03 0 0.02 0.27c0.63,7.01 6.54,12.4 13.58,12.4 7.05,0 12.95,-5.38 13.59,-12.4l0.02 -0.27 0.03 0 0 -28.75 2.86 0c28.06,0 51.02,-22.96 51.02,-51.02 0,-28.06 -22.96,-51.02 -51.02,-51.02l-32.99 0c-13.06,0 -23.74,-10.69 -23.74,-23.75 0,-13.06 10.68,-23.74 23.74,-23.74l56.89 0zm-318.25 -165.1l-23.92 23.92 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 23.92 23.92 519.72 0c37.25,0 67.72,-30.47 67.72,-67.72l0 -322.01c0,-37.24 -30.47,-67.71 -67.72,-67.71l-519.72 0zm-5.91 484.72l-51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 49.64 -49.64 0 -1.56 1.57 0 33.19 0 492.44 0c52.25,0 95,42.75 95,94.99l0 322.02c0,52.24 -42.75,94.99 -95,94.99l-492.44 0 -33.19 0z"
          />
        </svg>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </form>
</template>
